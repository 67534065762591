import {CMSEntryLink, CMSExternalLink, CMSImage, CMSWysiwyg} from 'cms-types'
import {Carousel, useCarousel} from '@codeurs/carousel'

import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {Icon} from 'layout/Icon'
import {Image} from 'layout/Image'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Testimonials.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type TestimonialsType = {
	items: Array<TestimonialItemType>
}

export type TestimonialItemType = {
	image: CMSImage
	quote: string
	name: string
	function: string
	title: string
	text: CMSWysiwyg
	button: CMSEntryLink | CMSExternalLink
}

type CarouselType = ReturnType<typeof useCarousel>

export const Testimonials: React.FC<TestimonialsType> = ({items}) => {
	const carousel = useCarousel()
	if (!items || !items.length) return null

	return (
		<div className={styles.testimonials()}>
			<Carousel
				{...carousel}
				className={styles.testimonials.carousel.mod(`length${items.length}`)()}
			>
				{items.map((testimonial, index: number) => (
					<div
						key={index}
						className={styles.testimonials.carousel.slide.is({
							active: carousel.isActive(index)
						})()}
					>
						<Container>
							<div className={styles.testimonials.carousel.slide.row()}>
								<TestimonialsLeft
									image={testimonial.image}
									testimonials={items}
									carousel={carousel}
								/>
								<TestimonialsRight {...testimonial} />
							</div>
						</Container>
					</div>
				))}
			</Carousel>
		</div>
	)
}

const TestimonialsLeft: React.FC<{
	image: CMSImage
	testimonials: Array<TestimonialItemType>
	carousel: CarouselType
}> = ({image, testimonials, carousel}) => {
	return (
		<div className={styles.left()}>
			<Image
				{...image}
				alt={image.alt || ''}
				as="square"
				ratioWidth={320}
				className={styles.left.image()}
				sizes="320px"
			/>
			<TestimonialsControls testimonials={testimonials} carousel={carousel} />
		</div>
	)
}

const TestimonialsRight: React.FC<TestimonialItemType> = ({
	quote,
	name,
	function: jobfunction,
	title,
	text,
	button
}) => {
	return (
		<div className={styles.right()}>
			<div className={styles.right.quote()}>
				<blockquote className={styles.right.quote.text()}>{quote}</blockquote>
			</div>
			{(name || jobfunction) && (
				<p className={styles.right.namefunction()}>
					{name && <strong>{name}</strong>}
					{name && jobfunction && ' - '}
					{jobfunction}
				</p>
			)}
			<span className={styles.right.bar()} />
			<div className={styles.right.content()}>
				<Title.H3
					className={styles.right.content.title()}
					mod={['uppercase', 'green']}
				>
					{title}
				</Title.H3>
				{text && (
					<Wysiwyg className={styles.right.content.text()}>{text}</Wysiwyg>
				)}
				{button?.url && (
					<Button
						to={button.url}
						className={styles.right.content.button()}
						mod="green"
					>
						{button.description || button.title}
					</Button>
				)}
			</div>
		</div>
	)
}

const TestimonialsControls: React.FC<{
	testimonials: Array<TestimonialItemType>
	carousel: CarouselType
}> = ({testimonials, carousel}) => {
	if (carousel.total < 2) return null

	return (
		<div className={styles.controls()}>
			<div className={styles.controls.row()}>
				<a
					onClick={() => carousel.goPrevious()}
					className={styles.controls.prev.is({
						disabled: !carousel.hasPrevious()
					})()}
				>
					<Icon icon="chevron_left" />
				</a>
				<p className={styles.controls.pagination()}>
					<span className={styles.controls.pagination.current()}>
						{carousel.current + 1}
					</span>
					<span>/</span>
					<span className={styles.controls.pagination.total()}>
						{carousel.total}
					</span>
				</p>
				<a
					onClick={() => carousel.goNext()}
					className={styles.controls.next.is({disabled: !carousel.hasNext()})()}
				>
					<Icon icon="chevron_right" />
				</a>
			</div>
		</div>
	)
}
