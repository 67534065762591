import {CMSImage, CMSLink, CMSWysiwyg} from 'cms-types'

import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Hero.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'

const styles = fromModule(css)

export type HeroType = {
	layout: 'small' | 'large'
} & HeroImageType &
	HeroContentType

type HeroImageType = {
	image: CMSImage
	overlay?: '0' | '25' | '50' | '65' | '75'
}

type HeroContentType = {
	title: string
	subtitle?: string
	text?: CMSWysiwyg
	buttons?: Array<CMSLink>
	warning?: CMSWysiwyg
}

export const Hero: React.FC<HeroType> = ({
	layout,
	image,
	overlay,
	title,
	subtitle,
	text,
	buttons,
	warning
}) => {
	const {node} = useApp()
	if (!image && !title) return null

	return (
		<div className={styles.hero.mod({layout}).mod(node?.channel)()}>
			<HeroImage image={image} overlay={overlay} />
			<HeroContent
				title={title}
				subtitle={subtitle}
				text={text}
				buttons={buttons}
			/>
		</div>
	)
}

const HeroImage: React.FC<HeroImageType> = ({image, overlay}) => {
	if (!image?.src) return null

	let overlayNumber: number = overlay && Number(overlay)
	if (isNaN(overlayNumber)) overlayNumber = 0
	const imageOverlay: number = overlayNumber === 0 ? 0 : overlayNumber / 100

	return (
		<div className={styles.image()}>
			<Image {...image} as="background" alt={image.alt || ''} priority={true} />
			<span
				className={styles.image.overlay()}
				style={{opacity: imageOverlay}}
			/>
		</div>
	)
}

const HeroContent: React.FC<HeroContentType> = ({
	title,
	subtitle,
	text,
	buttons
}) => {
	return (
		<Container>
			<div className={styles.content()}>
				{title && (
					<Title.H1 className={styles.content.title()} mod="inherit">
						{title}
					</Title.H1>
				)}
				{subtitle && (
					<Title.H2 className={styles.content.subtitle()} mod="inherit">
						{subtitle}
					</Title.H2>
				)}
				{text && <Wysiwyg className={styles.content.text()}>{text}</Wysiwyg>}
				{buttons && buttons?.length > 0 && (
					<div className={styles.content.buttons()}>
						{buttons.map(
							(button, i) =>
								button?.url && (
									<Button key={i} to={button.url} mod={['outline', 'white']}>
										{button.description || button.title}
									</Button>
								)
						)}
					</div>
				)}
			</div>
		</Container>
	)
}
