import {CMSFile} from 'cms-types'
import Image from 'next/legacy/image'
import css from './Country.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type CountryType = {
	flag: CMSFile
	city: string | null
	country: string | null
}

export const Country: React.FC<CountryType> = ({flag, city, country}) => {
	if (!city && !country) return null

	const title = country ? (
		<>
			<strong>{country}</strong>
			{city ? `,\n${city}` : null}
		</>
	) : (
		city
	)

	return (
		<div className={styles.country()}>
			{flag && (
				<div className={styles.country.flag()}>
					<Image
						className={styles.country.flag.img()}
						alt={country || ''}
						src={flag.url}
						width={25}
						height={25}
					/>
				</div>
			)}
			{title && <p className={styles.country.title()}>{title}</p>}
		</div>
	)
}
