export const setWithExpiry = (key: string, value: string, ttl: number) => {
	if (typeof window === 'undefined') return null
	const now: Date = new Date()
	const item: {
		value: string
		expiry: number
	} = {
		value: value,
		expiry: now.getTime() + ttl
	}
	localStorage.setItem(key, JSON.stringify(item))
}

export const getWithExpiry = (key: string) => {
	if (typeof window === 'undefined') return null
	const itemStr: string = localStorage.getItem(key)

	if (!itemStr) return null

	const now: Date = new Date()
	const item: {
		value: string
		expiry: number
	} = JSON.parse(itemStr)

	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key)
		return null
	}

	return false
}
