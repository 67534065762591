import {CMSEntryLink, CMSFile, CMSImage} from 'cms-types'

import {CardLabel} from 'layout/card/Card'
import {Country} from 'layout/country/Country'
import {Image} from 'layout/Image'
import {Link} from 'util/link'
import css from './HighlightCard.module.scss'
import {fromModule} from 'util/styler/Styler'

export type HighlightCardType = {
	city: string | null
	country: string | null
	image: CMSImage
	labels: Array<string>
	flag?: CMSFile
	link: CMSEntryLink
}

const styles = fromModule(css)

export const HighlightCard: React.FC<HighlightCardType> = ({
	city,
	country,
	image,
	labels,
	flag,
	link
}) => (
	<Link to={link?.url} className={styles.highlightcard()}>
		<div className={styles.highlightcard.image()}>
			<div className={styles.highlightcard.image.bg()}>
				<Image
					{...image}
					as="background"
					alt={image.alt || ''}
					sizes="(max-width: 599px) 310px, (max-width: 767px) 344px, (max-width: 1023px) 472px, (max-width: 1199px) 380px, 270px"
				/>
			</div>
			<span className={styles.highlightcard.image.overlay()} />
			{labels.map((label: string, index: number) => (
				<CardLabel key={index} label={label} />
			))}
		</div>
		<div className={styles.highlightcard.content()}>
			<Country flag={flag} city={city} country={country} />
			{link.description && (
				<p className={styles.highlightcard.content.bookingprice()}>
					{link.description}
				</p>
			)}
		</div>
	</Link>
)
