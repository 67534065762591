import {CMSImage, CMSLink, CMSWysiwyg} from 'cms-types'

import {Image} from 'layout/Image'
import {Link} from 'util/link'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Card.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type CardType = {
	image: CMSImage
	labels: Array<string>
	title: string
	text: CMSWysiwyg
	link?: CMSLink
}

export const Card: React.FC<CardType> = ({
	image,
	labels,
	title,
	text,
	link
}) => (
	<Link to={link?.url} className={styles.card()}>
		<div className={styles.card.image()}>
			{image?.src && (
				<div className={styles.card.image.bg()}>
					<Image
						{...image}
						as="background"
						alt={image.alt || ''}
						sizes="(max-width: 599px) 539px, (max-width: 767px) 344px, (max-width: 1023px) 472px, (max-width: 1199px) 367px, 390px"
					/>
				</div>
			)}
			{labels.map((label, index) => (
				<CardLabel key={index} label={label} />
			))}
		</div>
		<div className={styles.card.content()}>
			{title && (
				<Title.H3 className={styles.card.content.title()}>{title}</Title.H3>
			)}
			{text && (
				<Wysiwyg className={styles.card.content.text()}>
					{text.replace(/<a.*>(.*?)<\/a>/gi, '$1')}
				</Wysiwyg>
			)}
		</div>
	</Link>
)

export const CardLabel: React.FC<{label: string}> = ({label}) => {
	if (!label) return null
	return <p className={styles.label()}>{label}</p>
}
