import {useEffect, useState} from 'react'

type WindowDimentions = {
	width: number | undefined
	height: number | undefined
}

export const useWindowDimensions = (): WindowDimentions => {
	const [windowDimensions, setWindowDimensions] = useState<WindowDimentions>({
		width: undefined,
		height: undefined
	})
	useEffect(() => {
		function handleResize(): void {
			setWindowDimensions({
				width: window.innerWidth,
				height: window.innerHeight
			})
		}
		handleResize()
		window.addEventListener('resize', handleResize, {passive: true})
		return (): void => window.removeEventListener('resize', handleResize)
	}, []) // Empty array ensures that effect is only run on mount

	return windowDimensions
}

export const useIsTablet = (): boolean => {
	const {width} = useWindowDimensions()
	return width >= 600
}

export const useIsIpadPort = (): boolean => {
	const {width} = useWindowDimensions()
	return width >= 768
}

export const useIsIpadLand = (): boolean => {
	const {width} = useWindowDimensions()
	return width >= 1024
}

export const useIsDesktop = (): boolean => {
	const {width} = useWindowDimensions()
	return width >= 1200
}

export const useIsLarge = (): boolean => {
	const {width} = useWindowDimensions()
	return width >= 1600
}
