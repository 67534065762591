import {FormEvent, useEffect, useState} from 'react'
import {
	FormStep1,
	FormStep2,
	addInterests,
	subscribe
} from 'layout/newsletter/Newsletter'
import {getWithExpiry, setWithExpiry} from 'util/localStorage'

import {CMSWysiwyg} from 'cms-types'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './NewsletterBox.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type NewsletterBox = {
	title: string
	text: CMSWysiwyg
	placeholder: string
	btn: string
	agreement: CMSWysiwyg
	thanks_title: string
	thanks_text: CMSWysiwyg
}

export const NewsletterBox: React.FC<NewsletterBox> = ({
	agreement,
	btn,
	placeholder,
	text,
	thanks_title,
	thanks_text,
	title
}) => {
	const [isVisible, setIsVisible] = useState<boolean>(false)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [isExpired, setIsExpired] = useState<boolean>(false)
	const [email, setEmail] = useState<string>(null)
	const [errorMessage, setErrorMessage] = useState<string>(null)
	const [step, setStep] = useState<number>(1)
	const showNewsletterBoxAfterSeconds: number = 1

	useEffect(() => {
		setIsExpired(getWithExpiry('newsletterExpired'))
		const timer = setTimeout(() => {
			setIsVisible(true)
		}, showNewsletterBoxAfterSeconds * 1000)
		return () => clearTimeout(timer)
	}, [])

	if (isExpired === false) return null

	const setExpiry = () => {
		const storageDays: number = 28
		const storageMilliseconds: number = storageDays * (60 * 60 * 24 * 1000)
		setWithExpiry('newsletterExpired', 'false', storageMilliseconds)
	}

	return (
		<div
			className={styles.newsletterbox.is({
				visible: isVisible
			})()}
		>
			<a
				onClick={() => {
					setIsVisible(false)

					const closeTimer = setTimeout(() => {
						setExpiry()
						clearTimeout(closeTimer)
					}, 1000)
				}}
				className={styles.newsletterbox.close()}
			>
				✖
			</a>
			{step !== 2 && (
				<h3 className={styles.newsletterbox.title()}>
					{step === 3 ? thanks_title : title}
				</h3>
			)}
			{step !== 2 && (
				<div className={styles.newsletterbox.text()}>
					<Wysiwyg>{step === 3 ? thanks_text : text}</Wysiwyg>
				</div>
			)}
			{step !== 3 && (
				<form
					onSubmit={
						email === null
							? (event: FormEvent<HTMLFormElement>) =>
									subscribe(
										event,
										isSubmitting,
										setEmail,
										setErrorMessage,
										setIsSubmitting,
										setStep
									)
							: (event: FormEvent<HTMLFormElement>) =>
									addInterests(
										event,
										isSubmitting,
										setErrorMessage,
										setIsSubmitting,
										setStep,
										() => {
											const closeTimer = setTimeout(() => {
												setIsVisible(false)

												const closeTimer2 = setTimeout(() => {
													setExpiry()
													clearTimeout(closeTimer2)
												}, 1000)

												clearTimeout(closeTimer)
											}, 3000)
										}
									)
					}
					className={styles.newsletterbox.form()}
				>
					{step === 1 && (
						<FormStep1
							btn={btn}
							placeholder={placeholder}
							errorMessage={errorMessage}
							setErrorMessage={setErrorMessage}
							styles={styles.newsletterbox}
						/>
					)}
					{step === 2 && (
						<FormStep2
							email={email}
							errorMessage={errorMessage}
							styles={styles}
						/>
					)}
				</form>
			)}
			{step !== 3 && (
				<div className={styles.newsletterbox.agreement()}>
					<Wysiwyg>{agreement}</Wysiwyg>
				</div>
			)}
		</div>
	)
}
