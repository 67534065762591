import {Benefits, BenefitsType, InspirationType} from 'layout/benefits/Benefits'
import {
	CMSImage,
	CMSLink,
	CMSOkrareizenHomeChannel,
	CMSWysiwyg
} from 'cms-types'
import {Hero, HeroType} from 'layout/hero/Hero'
import React, {useEffect, useState} from 'react'

import {Button} from 'layout/Button'
import {CMSOkrareizenHomeChannelExtra} from './home.data'
import {Container} from 'layout/Container'
import {FeaturedDestinations} from 'layout/featureddestinations/FeaturedDestinations'
import {Image} from 'layout/Image'
import {NewsletterBox} from 'layout/newsletterbox/NewsletterBox'
import {Testimonials} from 'layout/testimonials/Testimonials'
import {Title} from 'layout/Title'
import {Wizard} from 'layout/wizard/Wizard'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Home.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'

const styles = fromModule(css)

type HerobrochureType = {
	title: string
	button: CMSLink
	image: CMSImage
}

export const Home: React.FC<
	CMSOkrareizenHomeChannel & CMSOkrareizenHomeChannelExtra
> = ({featureddestinations, destinationsUrl, ...node}) => {
	const {warning_text} = node
	const {general} = useApp()

	const hero: HeroType = {
		image: node.hero_image,
		overlay: node.hero_overlay,
		layout: node.hero_layout,
		title: node.hero_title || node.title,
		subtitle: node.hero_subtitle,
		text: node.hero_text,
		buttons: node.hero_buttons
	}

	const herobrochure: HerobrochureType = {
		title: node.herobrochure_title,
		button: node.herobrochure_button,
		image: node.herobrochure_image
	}

	const benefits: BenefitsType = {
		image: node.benefits_image,
		overlay: node.benefits_overlay,
		slogan: node.benefits_slogan,
		title: node.benefits_title,
		listitems: node.benefits_listitems
	}

	const inspiration: InspirationType = {
		title: node.inspiration_title,
		text: node.inspiration_text,
		button: node.inspiration_button,
		image: node.inspiration_image
	}

	return (
		<div className={styles.home()}>
			<div className={styles.home.hero()}>
				<HomeHeroWarning warning={warning_text} />
				<Hero {...hero} />
				<HomeHeroBrochure {...herobrochure} />
			</div>
			<Wizard destinationsUrl={destinationsUrl} />
			<FeaturedDestinations {...featureddestinations} />
			<Benefits benefits={benefits} inspiration={inspiration} />
			<Testimonials items={node.testimonials_items} />
			<NewsletterBox {...general.newsletterBox} />
		</div>
	)
}

const HomeHeroBrochure: React.FC<HerobrochureType> = ({
	title,
	button,
	image
}) => {
	if (!button?.url) return null

	return (
		<div
			className={styles.brochure.mod({
				image: image?.src
			})()}
		>
			<div className={styles.brochure.content()}>
				{title && (
					<Title.H2
						className={styles.brochure.content.title()}
						mod={['inherit', 'uppercase', 'extrabold']}
					>
						{title}
					</Title.H2>
				)}
				<Button
					to={button?.url}
					className={styles.brochure.content.button()}
					mod={['white', 'small']}
				>
					{button.description || button.title}
				</Button>
			</div>
			{image?.src && (
				<Image
					{...image}
					as="ratio"
					alt={image.alt || ''}
					className={styles.brochure.image()}
					sizes="200px"
					priority={true}
				/>
			)}
		</div>
	)
}

const HomeHeroWarning: React.FC<{warning?: CMSWysiwyg}> = ({warning}) => {
	const [isOpen, setOpen] = useState<boolean>(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setOpen(true)
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	if (!warning) return null

	return (
		<div className={styles.warning.is({open: isOpen})()}>
			<Container>
				<Wysiwyg className={styles.warning.text()}>{warning}</Wysiwyg>
			</Container>
		</div>
	)
}
