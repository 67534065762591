import {Carousel, useCarousel} from '@codeurs/carousel'
import {Dispatch, SetStateAction, useState} from 'react'
import {Icon, IconKey} from 'layout/Icon'

import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {Title} from 'layout/Title'
import css from './Wizard.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useIsIpadPort} from 'util/useWindowDimensions'
import {useRouter} from 'next/router'

const styles = fromModule(css)

export const Wizard: React.FC<{destinationsUrl: string}> = ({
	destinationsUrl
}) => {
	const router = useRouter()
	const isIpadPort = useIsIpadPort()
	const [filters, setFilters] = useState<Array<string>>([])
	const [step, setStep] = useState<number>(1)
	const [stepFilters, setstepFilters] = useState<{[key: string]: number}>({})

	const addOrRemoveFilter = (filter: string | Array<string>) => {
		const addRemoveFilters = Array.isArray(filter) ? [...filter] : [filter]
		const key = `step${step}`
		const newStepFilters = {...stepFilters}
		if (newStepFilters[key] === undefined) {
			newStepFilters[key] = 0
		}
		let newFilters = [...filters]
		addRemoveFilters.forEach((f) => {
			if (newFilters.includes(f)) {
				newFilters = newFilters.filter((newf) => newf !== f)
				newStepFilters[key] -= 1
			} else {
				newFilters = [...newFilters, f]
				newStepFilters[key] += 1
			}
		})
		setFilters(newFilters)
		setstepFilters(newStepFilters)
	}

	const showResults = () => {
		const filterString: string = filters.length ? `?${filters.join('&')}` : ''
		router.push(destinationsUrl + filterString)
	}

	return (
		<div className={styles.wizard()}>
			{isIpadPort ? (
				<Container>
					<WizardWrapper
						addOrRemoveFilter={addOrRemoveFilter}
						filters={filters}
						showResults={showResults}
						setStep={setStep}
						step={step}
						stepFilters={stepFilters}
						isIpadPort={isIpadPort}
					/>
				</Container>
			) : (
				<WizardWrapper
					addOrRemoveFilter={addOrRemoveFilter}
					filters={filters}
					showResults={showResults}
					setStep={setStep}
					step={step}
					stepFilters={stepFilters}
					isIpadPort={isIpadPort}
				/>
			)}
		</div>
	)
}

const WizardWrapper: React.FC<{
	addOrRemoveFilter: (filter: string | Array<string>) => void
	filters: Array<string>
	isIpadPort: boolean
	showResults: () => void
	setStep: Dispatch<SetStateAction<number>>
	step: number
	stepFilters: {
		[key: string]: number
	}
}> = ({
	addOrRemoveFilter,
	filters,
	isIpadPort,
	showResults,
	setStep,
	step,
	stepFilters
}) => {
	return (
		<div className={styles.wizard.wrapper()}>
			<div className={styles.wizard.container()}>
				<div className={styles.wizard.header()}>
					<Flag currentStep={step} />
				</div>
				<div className={styles.wizard.content()}>
					<div
						className={styles.wizard.content.steps()}
						style={{transform: `translateX(${(step - 1) * 100 * -1}%)`}}
					>
						<Step1
							filters={filters}
							onClickHandler={(filter) => {
								addOrRemoveFilter(filter)
							}}
						/>
						<Step2
							filters={filters}
							onClickHandler={(filter) => {
								addOrRemoveFilter(filter)
							}}
						/>
					</div>
				</div>
				{isIpadPort ? (
					<ExtendedFooter
						showResults={showResults}
						setStep={setStep}
						step={step}
						stepFilters={stepFilters}
					/>
				) : (
					<SimpleFooter
						showResults={showResults}
						setStep={setStep}
						step={step}
						stepFilters={stepFilters}
					/>
				)}
			</div>
		</div>
	)
}

const ExtendedFooter: React.FC<{
	showResults: () => void
	setStep: Dispatch<SetStateAction<number>>
	step: number
	stepFilters: {
		[key: string]: number
	}
}> = ({showResults, setStep, step, stepFilters}) => {
	return (
		<div className={styles.wizard.extendedfooter()}>
			<div className={styles.wizard.extendedfooter.results()}>
				<a
					className={styles.wizard.extendedfooter.results.link()}
					onClick={() => showResults()}
				>
					Toon resultaten &gt;
				</a>
			</div>
			<div className={styles.wizard.extendedfooter.steps()}>
				{[1, 2].map((stepNumber: number) => {
					return (
						<a
							key={stepNumber}
							className={styles.wizard.extendedfooter.steps.step.is({
								disabled: step < stepNumber,
								active: step === stepNumber,
								completed: step > stepNumber
							})()}
							onClick={() => {
								if (step >= stepNumber) setStep(stepNumber)
							}}
						>
							<span>{stepNumber}</span>
						</a>
					)
				})}
			</div>
			<NextButton
				onClickHandler={step === 1 ? () => setStep(2) : () => showResults()}
				step={step}
				stepFilters={stepFilters}
				text={step === 1 ? 'Stap 2: Kies je vervoer' : 'Toon mijn resultaten'}
			/>
		</div>
	)
}

const SimpleFooter: React.FC<{
	showResults: () => void
	setStep: Dispatch<SetStateAction<number>>
	step: number
	stepFilters: {
		[key: string]: number
	}
}> = ({showResults, setStep, step, stepFilters}) => {
	const showNextButton: boolean =
		step !== 2 || !stepFilters[`step${step}`]
			? true
			: stepFilters[`step${step}`] && stepFilters[`step${step}`] < 1

	return (
		<div className={styles.wizard.simplefooter()}>
			<div
				className={styles.wizard.simplefooter.results.is({
					fullWidth: !showNextButton
				})()}
			>
				<a
					className={styles.wizard.simplefooter.results.link()}
					onClick={() => showResults()}
				>
					Toon resultaten
				</a>
			</div>
			{showNextButton ? (
				<NextButton
					onClickHandler={step === 1 ? () => setStep(2) : () => showResults()}
					step={step}
					stepFilters={stepFilters}
					text={step === 1 ? 'Volgende' : 'Toon resultaten'}
				/>
			) : null}
		</div>
	)
}

const NextButton: React.FC<{
	onClickHandler: () => void
	step: number
	stepFilters: {[key: string]: number}
	text: string
}> = ({onClickHandler, step, stepFilters, text}) => {
	return (
		<Button
			className={styles.nextbutton()}
			onClick={() => {
				if (stepFilters[`step${step}`] && stepFilters[`step${step}`] >= 1)
					onClickHandler()
			}}
			mod={[
				'orange',
				!stepFilters[`step${step}`] ||
				(stepFilters[`step${step}`] && stepFilters[`step${step}`] < 1)
					? 'disabled'
					: null
			]}
		>
			{text}
		</Button>
	)
}

const Flag: React.FC<{currentStep: number}> = ({currentStep}) => {
	return (
		<div
			className={styles.flag.is({
				active: currentStep === 1
			})()}
		>
			<p className={styles.flag.text()}>
				Vind jouw
				<br />
				Okra reis in
				<br />
				twee stappen
			</p>
		</div>
	)
}

const Step1: React.FC<{
	filters: Array<string>
	onClickHandler: (filter: string) => void
}> = ({filters, onClickHandler}) => {
	const carousel = useCarousel()

	return (
		<div className={styles.step()}>
			<Title.H3 className={styles.step.title()}>
				Wat wil je graag doen op reis?
			</Title.H3>
			<div className={styles.step.options.mod('cols5')()}>
				<Carousel {...carousel}>
					<FilterItem
						filter={['destinationtypes=Cultuurvakanties']}
						filters={filters}
						onClickHandler={onClickHandler}
						text={'Cultuur'}
						icon={'culture'}
					/>
					<FilterItem
						filter={['destinationtypes=Wandelvakanties']}
						filters={filters}
						onClickHandler={onClickHandler}
						text={'Wandelen'}
						icon={'walking'}
					/>
					<FilterItem
						filter={['destinationtypes=Fietsvakanties']}
						filters={filters}
						onClickHandler={onClickHandler}
						text={'Fietsen'}
						icon={'cycling'}
					/>
					<FilterItem
						filter={['destinationtypes=Verblijfsvakanties']}
						filters={filters}
						onClickHandler={onClickHandler}
						text={'Verblijfsvakanties'}
						icon={'residence_holidays'}
					/>
					<FilterItem
						filter={['destinationtypes=Singlereizen']}
						filters={filters}
						onClickHandler={onClickHandler}
						text={'Single reizen'}
						icon={'travel'}
					/>
				</Carousel>
			</div>
		</div>
	)
}

const Step2: React.FC<{
	filters: Array<string>
	onClickHandler: (filter: string) => void
}> = ({filters, onClickHandler}) => {
	const carousel = useCarousel()

	return (
		<div className={styles.step()}>
			<Title.H3 className={styles.step.title()}>
				Hoe wil je er geraken?
			</Title.H3>
			<div className={styles.step.options.mod('cols4')()}>
				<Carousel {...carousel}>
					<FilterItem
						filter={['otherfilters=Vervoer_Bus']}
						filters={filters}
						onClickHandler={onClickHandler}
						text={'Bus'}
						icon={'bus'}
					/>
					<FilterItem
						filter={['otherfilters=Vervoer_Vliegtuig']}
						filters={filters}
						onClickHandler={onClickHandler}
						text={'Vliegtuig'}
						icon={'plane'}
					/>
					<FilterItem
						filter={['otherfilters=Vervoer_Eigen+vervoer']}
						filters={filters}
						onClickHandler={onClickHandler}
						text={'Eigen vervoer'}
						icon={'car'}
					/>
					<FilterItem
						filter={['otherfilters=Vervoer_Motor']}
						filters={filters}
						onClickHandler={onClickHandler}
						text={'Motor'}
						icon={'motor'}
					/>
				</Carousel>
			</div>
		</div>
	)
}

const FilterItem: React.FC<{
	filter: string | Array<string>
	filters: Array<string>
	icon: IconKey
	onClickHandler: (filter: string | Array<string>) => void
	text: string
}> = ({filter, filters, icon, onClickHandler, text}) => {
	let isActive: boolean = false

	if (Array.isArray(filter)) {
		isActive = true
		filter.forEach((f) => {
			if (!filters.includes(f)) {
				isActive = false
			}
		})
	} else {
		isActive = filters.includes(filter)
	}

	return (
		<a
			onClick={() => onClickHandler(filter)}
			className={styles.step.options.option.is({
				active: isActive
			})()}
		>
			<div className={styles.step.options.option.icon()}>
				<Icon icon={icon} />
			</div>
			<div className={styles.step.options.option.text()}>{text}</div>
		</a>
	)
}
