import {CMSEntryLink, CMSExternalLink, CMSImage, CMSWysiwyg} from 'cms-types'

import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Benefits.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type BenefitsType = {
	image: CMSImage
	overlay: '0' | '25' | '50' | '65' | '75'
	slogan: CMSImage
	title: string
	listitems: CMSWysiwyg
}

export type InspirationType = {
	title?: string
	text?: CMSWysiwyg
	button?: CMSEntryLink | CMSExternalLink
	image?: CMSImage
}

export const Benefits: React.FC<{
	benefits: BenefitsType
	inspiration: InspirationType
}> = ({benefits, inspiration}) => {
	let overlayNumber: number = benefits?.overlay && Number(benefits.overlay)
	if (isNaN(overlayNumber)) overlayNumber = 0
	const imageOverlay: number = overlayNumber === 0 ? 0 : overlayNumber / 100

	return (
		<div className={styles.benefits()}>
			<div className={styles.benefits.image()}>
				<div className={styles.benefits.image.bg()}>
					<Image
						{...benefits?.image}
						alt={benefits?.image?.alt || ''}
						as="background"
					/>
				</div>
				<span
					className={styles.benefits.image.overlay()}
					style={{opacity: imageOverlay}}
				/>
			</div>
			<Container>
				<div className={styles.benefits.row()}>
					<div className={styles.benefits.row.left()}>
						<BenefitsInspiration {...inspiration} />
					</div>
					<div className={styles.benefits.row.right()}>
						<BenefitsContent {...benefits} />
					</div>
				</div>
			</Container>
		</div>
	)
}

const BenefitsInspiration: React.FC<InspirationType> = ({
	title,
	text,
	button,
	image
}) => {
	if (!title && !text && !button) return null

	return (
		<div className={styles.inspiration()}>
			{title && (
				<Title.H2
					className={styles.inspiration.title()}
					mod={['blue', 'uppercase']}
				>
					{title}
				</Title.H2>
			)}
			{text && <Wysiwyg className={styles.inspiration.text()}>{text}</Wysiwyg>}
			{button?.url && (
				<Button to={button.url} className={styles.inspiration.button()}>
					{button.description || button.title}
				</Button>
			)}
			{image?.src && (
				<Image
					{...image}
					as="ratio"
					alt={image.alt || ''}
					className={styles.inspiration.image()}
					sizes="380px"
				/>
			)}
		</div>
	)
}

const BenefitsContent: React.FC<BenefitsType> = ({title, listitems}) => {
	if (!title || !listitems) return null

	return (
		<div className={styles.content()}>
			{title && (
				<Title.H2
					className={styles.content.title()}
					mod={['green', 'uppercase']}
				>
					{title}
				</Title.H2>
			)}
			{listitems && (
				<Wysiwyg
					className={styles.content.listitems()}
					mod="green"
					listStyle="pentagons"
				>
					{listitems}
				</Wysiwyg>
			)}
		</div>
	)
}
