import {CMSOkrareizenChannel, CMSOkrareizenHomeChannel} from 'cms-types'
import {GeneralData, GeneralRedirect, fetchGeneralData} from 'server/general'

import {CMSOkrareizenHomeChannelExtra} from 'channels/okrareizen/home/home.data'
import {GetStaticProps} from 'next'
import {Home as HomeChannel} from 'channels/okrareizen/home/Home'
import {fetchNodeData} from 'server/node'

const Home: React.FC<CMSOkrareizenChannel> = (data) => (
	<HomeChannel
		{...(data as CMSOkrareizenHomeChannel & CMSOkrareizenHomeChannelExtra)}
	/>
)

export default Home

export const getStaticProps: GetStaticProps = async (context) => {
	let general = await fetchGeneralData()
	if ((general as GeneralRedirect)?.redirectUrl) {
		return {
			redirect: {
				permanent: false,
				destination: (general as GeneralRedirect).redirectUrl
			}
		}
	}
	let node = await fetchNodeData('/')

	if (!node) {
		return {notFound: true}
	}

	// Akward JSON parse/stringify because of https://github.com/vercel/next.js/discussions/11209
	general = JSON.parse(JSON.stringify(general as GeneralData))
	node = JSON.parse(JSON.stringify(node))

	return {
		props: {
			node,
			general
		},
		revalidate: 1
	}
}
