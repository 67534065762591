import {CMSEntryLink, CMSExternalLink, CMSWysiwyg} from 'cms-types'
import {Carousel, useCarousel} from '@codeurs/carousel'
import {
	HighlightCard,
	HighlightCardType
} from 'layout/highlightcard/HighlightCard'

import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {Icon} from 'layout/Icon'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './FeaturedDestinations.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type FeaturedDestinationsType = FeaturedOverviewType & {
	content: FeaturedContentType
}

type FeaturedOverviewType = {
	title: string
	items: Array<HighlightCardType>
}

type FeaturedContentType = {
	title: string
	text?: CMSWysiwyg
	button: CMSEntryLink | CMSExternalLink
}

export const FeaturedDestinations: React.FC<FeaturedDestinationsType> = ({
	title,
	items,
	content
}) => {
	if (!items || (items && items.length === 0)) return null

	return (
		<div className={styles.featureddestinations()}>
			<Container>
				<div className={styles.featureddestinations.row()}>
					<FeaturedOveriew title={title} items={items} />
					<FeaturedContent {...content} />
				</div>
			</Container>
		</div>
	)
}

const FeaturedOveriew: React.FC<FeaturedOverviewType> = ({title, items}) => {
	const carousel = useCarousel()

	return (
		<div className={styles.overview()}>
			{title && <h4 className={styles.overview.title()}>{title}</h4>}
			{items && items.length > 0 && (
				<div className={styles.overview.carousel()}>
					<div className={styles.overview.carousel.items()}>
						<Carousel {...carousel}>
							{items.map((card, index) => (
								<div
									key={index}
									className={styles.overview.carousel.items.item.is({
										active: carousel.isActive(index)
									})()}
								>
									<HighlightCard {...card} />
								</div>
							))}
						</Carousel>
					</div>
				</div>
			)}
			<FeaturedCarouselControls carousel={carousel} />
		</div>
	)
}

type CarouselType = ReturnType<typeof useCarousel>

const FeaturedCarouselControls: React.FC<{
	carousel: CarouselType
}> = ({carousel}) => {
	if (carousel.total < 2) return null

	return (
		<>
			<a
				onClick={() => carousel.goPrevious()}
				className={styles.controls.prev.is({
					disabled: !carousel.hasPrevious()
				})()}
			>
				<Icon icon="chevron_left" />
			</a>
			<a
				onClick={() => carousel.goNext()}
				className={styles.controls.next.is({
					disabled: !carousel.hasNext()
				})()}
			>
				<Icon icon="chevron_right" />
			</a>
		</>
	)
}

const FeaturedContent: React.FC<FeaturedContentType> = ({
	title,
	text,
	button
}) => {
	if (!title && !text && !button) return null

	return (
		<div className={styles.content()}>
			{title && (
				<Title.H5
					className={styles.content.title()}
					mod={['uppercase', 'extrabold']}
				>
					{title}
				</Title.H5>
			)}
			{text && <Wysiwyg className={styles.content.text()}>{text}</Wysiwyg>}
			{button?.url && (
				<Button to={button.url} className={styles.content.button()}>
					{button.description || button.title}
				</Button>
			)}
		</div>
	)
}
